import React from "react"
import { orientationPhotos } from "../data/photos/photo-gallery-data"
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
export default function OrientationGallery() {
  return (
    <>
      <div className="mt-5 pt-5 mb-5 px-5 container-custom">
        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 3, 750: 4, 900: 5 }}>
          <Masonry gutter="10px">
            {orientationPhotos.map(data => {
              return <img src={data} alt={``} />
            })}
          </Masonry>
        </ResponsiveMasonry>
      </div>
    </>
  )
}
